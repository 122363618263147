import React from "react";
import { graphql } from "gatsby";

import navigation from "src/data";
import Header from "components/Header";
import Main from "components/Main";
import Footer from "components/Footer";
import SEO from "components/SEO";

import "styles/styles.scss";

const PrivatumoPolitika = ({ data }) => {
  return (
    <>
      <SEO />
      {data.allContentfulLanding.edges.map((edge, idx) => (
        <div key={idx}>
          <Header
            items={navigation}
            logo={edge.node.logo.fluid}
            logoAlt="Solaw Logotipas"
            navPosition="right"
          />
          <Main isPrivacyPage={true}>
            <div
              dangerouslySetInnerHTML={{
                __html: edge.node.privacyPolicy.childContentfulRichText.html,
              }}
            />
            {/* <Table /> Slapuku lentele*/}
          </Main>
          <Footer isPrivacyPage={true} />
        </div>
      ))}
    </>
  );
};

export const query = graphql`
  {
    allContentfulLanding {
      edges {
        node {
          logo {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          privacyPolicy {
            childContentfulRichText {
              html
            }
          }
        }
      }
    }
  }
`;

export default PrivatumoPolitika;
